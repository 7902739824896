import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestUserLoginPlayer } from '~/services/auth';
import { loginCurrentUserSuccess, logoutCurrentUserSuccess } from '~/redux/reducer/auth';

function PaymentLogin({ game }) {
    const [roleId, setRoleId] = useState('');
    const [errorLogin, setErrorLogin] = useState('');
    const [loadingLogin, setLoadingLogin] = useState(false);

    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);

    const handleGuideViewID = () => {
        document.getElementById('Popup_Container_Div').classList.toggle('hidden');
    };

    const handleLogoutPlayer = () => {
        dispatch(logoutCurrentUserSuccess());
    };

    const handleOnChangeRoleId = (e) => {
        const value = e.target.value;

        setErrorLogin('');
        setRoleId(value);

        if (!value) {
            setErrorLogin('Thông tin nhân vật không hợp lệ');
        }
    };

    const handleLoginPlayer = async () => {
        if (!roleId) {
            setErrorLogin('Vui lòng nhập ID hoặc tên người dùng');
            return;
        }
        if (!game.code) {
            setErrorLogin('Vui lòng chọn phương thức đăng nhập');
            return;
        }

        setLoadingLogin(true);

        const data = {
            role_id: roleId,
            code: game.code,
        };

        const result = await requestUserLoginPlayer(data);

        setLoadingLogin(false);
        if (result.status === 200) {
            dispatch(loginCurrentUserSuccess(result.data));
        } else {
            setErrorLogin(result.error || 'Lỗi đăng nhập vui lòng thử lại sau');
        }
    };

    return (
        <div data-v-78ee7054="" className="sso-login">
            <div data-v-78ee7054="" className="content-box content-box-redesign-ui authentication">
                <div className="mask"></div>
                <div className="content-box__header">
                    <div className="content-box__header-container">
                        <span className="content-box__header-number">1</span>
                        <span className="content-box__header-content">
                            {!currentUser || currentUser.game?.code !== game?.code ? 'Chọn phương thức đăng nhập' : 'Thông tin nhân vật'}
                        </span>
                    </div>

                    {!currentUser || currentUser.game.code !== game?.code ? (
                        <div data-v-78ee7054="" className="IdentificationCustomInfo QUICK INLINE">
                            <div className="GET_UID_IMAGE" onClick={handleGuideViewID}>
                                Hướng dẫn tìm ID
                            </div>
                        </div>
                    ) : (
                        <div data-v-78ee7054="" style={{ marginBottom: 4 }} className="IdentificationCustomInfo QUICK INLINE">
                            <div className="GET_UID_IMAGE" onClick={handleLogoutPlayer}>
                                Đăng xuất
                            </div>
                        </div>
                    )}
                </div>

                {currentUser && currentUser.game.code === game?.code ? (
                    <div className="user-info">
                        <div className="user-info_item">
                            <div className="user-info_title">
                                <div className="user-info_title_text">ID nhân vật:</div>
                            </div>

                            <div className="user-info_name">{currentUser.role_id}</div>
                        </div>
                        <div className="user-info_item">
                            <div className="user-info_title">
                                <div className="user-info_title_text">Nhân vật:</div>
                            </div>

                            <div className="user-info_name">{currentUser.role_name}</div>
                        </div>
                    </div>
                ) : (
                    <div className="content-box__content">
                        <div data-v-178a61b8="" data-v-78ee7054="" className="sso-login-quick">
                            <div data-v-7697c618="" data-v-178a61b8="" className="quick-login-form content-redesign-ui">
                                <form data-v-7697c618="" className="el-form quick-login-form__el-form">
                                    <div data-v-7697c618="" className="el-form-item is-required is-no-asterisk">
                                        <div className="el-form-item__content">
                                            <div data-v-7697c618="">
                                                <div className="el-input el-input--suffix">
                                                    <input
                                                        type="text"
                                                        placeholder={
                                                            game?.login_type === 'role' ? 'Nhập ID hoặc tên người dùng' : 'Nhập ID nhân vật'
                                                        }
                                                        className={`el-input__inner ${errorLogin ? 'valid_login' : ''}`}
                                                        value={roleId}
                                                        onChange={(e) => handleOnChangeRoleId(e)}
                                                    />

                                                    {errorLogin && <span className="valid_login">{errorLogin}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div data-v-7697c618="" className="quick-login-form__tooltip">
                                    {game?.description && (
                                        <Fragment>
                                            <b>Lưu ý: </b>
                                            {game?.description}
                                        </Fragment>
                                    )}
                                </div>
                                <div data-v-7697c618="" className="quick-login-form__btn">
                                    <button
                                        data-v-0cf9d480=""
                                        data-v-7697c618=""
                                        className="el-button btn-primary--new-design el-button--primary"
                                        disabled={loadingLogin}
                                        onClick={handleLoginPlayer}
                                    >
                                        {loadingLogin ? (
                                            <div className="quick-login-loading">
                                                <div className="quick-login-spin"></div>
                                            </div>
                                        ) : (
                                            <span>Xác nhận</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div data-v-178a61b8="" className="sso-login-quick__divider">
                                <p data-v-178a61b8="">Hoặc đăng nhập với</p>
                            </div>
                            <div data-v-178a61b8="" className="sso-login-quick__other-methods">
                                <div data-v-178a61b8="">
                                    <div data-v-178a61b8="" className="sso-login-method">
                                        <div className="zing sso-login-method--compact-btn">
                                            <div className="btn-link">
                                                <img
                                                    className="invert-color zing"
                                                    src="https://shop.vnggames.com/primo/static/img//sso-login-method/login-zing.svg"
                                                    alt="ZingID"
                                                />
                                                <span>ZingID</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-178a61b8="">
                                    <div data-v-178a61b8="" className="sso-login-method">
                                        <div className="guest sso-login-method--compact-btn">
                                            <div className="btn-link">
                                                <img
                                                    className="invert-color guest"
                                                    src="https://shop.vnggames.com/primo/static/img//sso-login-method/login-guest.svg"
                                                    alt="Chơi ngay"
                                                />
                                                <span>Chơi ngay</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PaymentLogin;
