import request from '~/utils';

export const requestUserGetApps = async () => {
    try {
        const res = await request.get('/apps');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
export const requestUserGetAppDetail = async (app) => {
    try {
        const res = await request.get(`/apps/${app}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUserGetAppPackages = async (code) => {
    try {
        const res = await request.get(`/apps/packages`, {
            params: {
                code,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUserGetAppTelcos = async () => {
    try {
        const res = await request.get(`/apps/telcos`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
