import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className="notfound">
            <h1>Error has occurred</h1>
            <div className="ghost">
                <div className="body-g">
                    <div className="face-g">
                        <div className="eye-g"></div>
                        <div className="eye-g ri"></div>
                        <div className="mouth-g"></div>
                    </div>
                    <div className="left-hand"></div>
                    <div className="right-hand"></div>
                    <div className="rim-g">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="left-ground"></div>
                    <div className="right-ground"></div>
                </div>
            </div>
            <p className="text-err">There was a problem connecting to the server</p>
            <Link to="/" className="back-home">
                Back to home page
            </Link>
        </div>
    );
}

export default NotFound;
