import Home from '~/layouts/Home';
import NotFound from '~/layouts/NotFound';
import Result from '~/layouts/components/Result';
import Payment from '~/layouts/components/Payment';
import Billing from '~/layouts/components/Billing';
import BillingResult from '~/layouts/components/BillingResult';
import BillingPayment from '~/layouts/components/BillingPayment';

const publicRoutes = [
    { path: '/', component: Home },
    { path: '/:code', component: Payment },
    { path: '/billing', component: Billing },
    { path: '*', component: NotFound, layout: null },
    { path: '/billing/:code', component: BillingPayment },
    { path: '/:code/result/:request_id', component: Result },
    { path: '/billing/:code/result', component: BillingResult },
];

export default publicRoutes;
