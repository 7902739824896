import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ForYou from '~/components/ForYou';
import { requestUserGetApps } from '~/services/app';

function Landing({ navigateGame = '/' }) {
    const [games, setGames] = useState([]);
    const [category, setCategory] = useState('all');
    const [visibleCount, setVisibleCount] = useState(15);

    const navigate = useNavigate();

    useEffect(() => {
        const fetch = async () => {
            const result = await requestUserGetApps();

            if (result.status === 200) {
                setGames(result.data);
            }
        };

        fetch();
    }, []);

    const filteredGames =
        category === 'all' ? games.slice(0, visibleCount) : games.filter((game) => game.category === category).slice(0, visibleCount);

    const handleFilterChange = (selectedCategory) => {
        setCategory(selectedCategory);
        setVisibleCount(15);
    };

    const handleLoadMore = () => {
        setVisibleCount((prevCount) => prevCount + 15);
    };

    const handleSelectGame = (game) => {
        if (!game || !game.code) {
            alert('Vui lòng chọn game muốn nạp');
        }

        navigate(`${navigateGame}${game.code}`);
    };

    return (
        <div className="landing">
            <div className="container">
                <div data-v-35942c86="" className="wrap-section-recommend">
                    <ForYou />

                    <div className="list-game-title custom-radio-button filter">
                        <h3 className="home-title">Danh sách game</h3>
                        <div className="wrap-filtering">
                            <div
                                data-v-6f30cddf=""
                                className={`filter-btn ${category === 'all' ? 'selected' : ''}`}
                                onClick={() => handleFilterChange('all')}
                            >
                                {category === 'all' && (
                                    <img
                                        data-v-6f30cddf=""
                                        alt="icon-search"
                                        className="icon-selected"
                                        src="https://shop.vnggames.com/static/img/icon-filter.svg"
                                    />
                                )}
                                <div data-v-6f30cddf="" className={`btn-custom ${category === 'all' ? 'btn-custom-select' : ''}`}>
                                    Tất cả
                                </div>
                            </div>
                            <div
                                data-v-6f30cddf=""
                                className={`filter-btn ${category === 'mobile' ? 'selected' : ''}`}
                                onClick={() => handleFilterChange('mobile')}
                            >
                                {category === 'mobile' && (
                                    <img
                                        data-v-6f30cddf=""
                                        alt="icon-search"
                                        className="icon-selected"
                                        src="https://shop.vnggames.com/static/img/icon-filter.svg"
                                    />
                                )}
                                <div data-v-6f30cddf="" className={`btn-custom ${category === 'mobile' ? 'btn-custom-select' : ''}`}>
                                    Mobile
                                </div>
                            </div>
                            <div
                                data-v-6f30cddf=""
                                className={`filter-btn ${category === 'pc' ? 'selected' : ''}`}
                                onClick={() => handleFilterChange('pc')}
                            >
                                {category === 'pc' && (
                                    <img
                                        data-v-6f30cddf=""
                                        alt="icon-search"
                                        className="icon-selected"
                                        src="https://shop.vnggames.com/static/img/icon-filter.svg"
                                    />
                                )}
                                <div data-v-6f30cddf="" className={`btn-custom ${category === 'pc' ? 'btn-custom-select' : ''}`}>
                                    PC
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="wrapper">
                            {filteredGames.map((game, index) => (
                                <div key={index}>
                                    <div title={game.title} className="wrap-game" onClick={() => handleSelectGame(game)}>
                                        <div className="top-item">
                                            <div className="wrap-img-item">
                                                <picture>
                                                    <source type="image/webp" srcSet={game.avatar_url} />
                                                    <img src={game.avatar_url} alt="thumb-img-game" className="img-game" />
                                                </picture>
                                            </div>
                                            <div className="titleGame">{game.title}</div>
                                        </div>
                                        <div className="wrap-btnNap">
                                            <button className="btnNap">Nạp ngay</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {visibleCount < games.length && (
                            <div className="load-more" onClick={handleLoadMore}>
                                <div className="load-more__content">Xem thêm</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;
