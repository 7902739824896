import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { convertCurrency } from '~/configs';
import { requestUserConfirmPayment } from '~/services/payment';

function isNumericString(input) {
    if (typeof input !== 'string') {
        return false;
    }

    if (typeof input === 'number') {
        return true;
    }

    return /^[0-9]+$/.test(input);
}

function PaymentConfirm({ telcoSelect, packageSelect }) {
    const [code, setCode] = useState('');
    const [serial, setSerial] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [errorSerial, setErrorSerial] = useState('');
    const [activeCode, setActiveCode] = useState(false);
    const [activeSerial, setActiveSerial] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);

    const navigate = useNavigate();
    const { code: codeGame } = useParams();
    const { currentUser } = useSelector((state) => state.auth);

    const handleOnChangeSeriCode = (e, type) => {
        const value = e.target.value;

        const isNumber = isNumericString(value);

        if (type === 'serial') {
            setSerial(value);

            if (value.length !== telcoSelect?.serial_length || !isNumber) {
                setActiveSerial(false);
                setErrorSerial('Định dạng serial không hợp lệ');
            } else {
                setErrorSerial('');
                setActiveSerial(true);
            }
        }
        if (type === 'code') {
            setCode(value);
            if (value.length !== telcoSelect?.code_length || !isNumber) {
                setActiveCode(false);
                setErrorCode('Định dạng mã thẻ không hợp lệ');
            } else {
                setErrorCode('');
                setActiveCode(true);
            }
        }
    };

    const handleConfirmPayment = async () => {
        const data = {
            code: code,
            app: codeGame,
            serial: serial,
            telco: telcoSelect.code,
            package_id: packageSelect.id,
            role_id: currentUser.role_id,
        };

        setLoadingPayment(true);

        const result = await requestUserConfirmPayment(data);

        setLoadingPayment(false);
        if (result.status === 99) {
            navigate(`/${codeGame}/result/${result.data}`);
        } else if (result.status === 1) {
            setErrorSerial(result.error);
        } else {
            setErrorCode(result.error || 'Lỗi thanh toán vui lòng thử lại sau');
        }
    };

    return (
        <div className="wrap-confirmation">
            <div data-v-706b0058="" className="confirm-single confirm content-box content-box-redesign-ui">
                <div className="mask"></div>
                <div className="content-box__header">
                    <div className="content-box__header-container">
                        <span className="content-box__header-number">4</span>
                        <span className="content-box__header-content">Xác nhận thông tin</span>
                    </div>
                </div>
                <div className="content-box__content">
                    <div data-v-706b0058="" className="wrap-package">
                        {packageSelect ? (
                            <div data-v-706b0058="" className="info-package">
                                <div data-v-706b0058="" className="info-left">
                                    <img data-v-706b0058="" src={packageSelect.image_url} alt={packageSelect.title} />
                                    <div>
                                        <div data-v-706b0058="" className="name">
                                            {packageSelect.title}
                                        </div>
                                        <div data-v-706b0058="" className="price">
                                            {convertCurrency(packageSelect.amount.value)} VND
                                        </div>
                                    </div>
                                </div>

                                <div data-v-8fa8c050="" className="info-right">
                                    <div data-v-8fa8c050="" className="quantity">
                                        x1
                                    </div>
                                    <i data-v-8fa8c050="" className="icon-delete el-icon-close"></i>
                                </div>
                            </div>
                        ) : (
                            <div data-v-706b0058="">
                                <div data-v-706b0058="" className="empty-package">
                                    Chọn gói nạp
                                </div>
                            </div>
                        )}
                    </div>

                    <div data-v-706b0058="" className="wrap-info-payment-detail">
                        <div data-v-706b0058="" className="--title">
                            Chi tiết thanh toán
                        </div>
                        <div data-v-706b0058="" className="list-info">
                            <div data-v-706b0058="" className="top-content">
                                <div data-v-706b0058="" className="item-info">
                                    <div data-v-706b0058="" className="title">
                                        Tổng tiền:
                                    </div>
                                    <div data-v-706b0058="" className="value">
                                        {convertCurrency(packageSelect?.amount.value)} VND
                                    </div>
                                </div>
                            </div>
                            <div data-v-706b0058="" className="bottom-content">
                                <div data-v-706b0058="" className="total-payment">
                                    Tổng thanh toán
                                </div>
                                <div data-v-706b0058="" className="price-total-payment">
                                    {convertCurrency(packageSelect?.amount.value)} VND
                                </div>
                            </div>
                        </div>
                    </div>

                    <div data-v-706b0058="" className="confirm-payment-method">
                        <div data-v-706b0058="" className="title">
                            Phương thức thanh toán
                        </div>
                        {telcoSelect ? (
                            <div data-v-706b0058="" className="wrap-content">
                                <div data-v-706b0058="" className="telco-detail">
                                    <img src={telcoSelect.image_url} alt={telcoSelect.title} />
                                    <div data-v-706b0058="" className="name">
                                        {telcoSelect.title}
                                    </div>
                                </div>
                                <div data-v-706b0058="" className="vnggamescard-promo-block">
                                    <div data-v-706b0058="" className="pmt-group__promo__hint">
                                        <div data-v-7697c618="" data-v-178a61b8="" className="quick-login-form content-redesign-ui">
                                            <form data-v-7697c618="" className="el-form quick-login-form__el-form">
                                                <div data-v-7697c618="" className="el-form-item is-required is-no-asterisk">
                                                    <div className="el-form-item__content">
                                                        <div data-v-7697c618="">
                                                            <div className="el-input el-input--suffix">
                                                                <input
                                                                    type="text"
                                                                    className={`el-input__inner payment ${
                                                                        errorSerial ? 'valid_login' : ''
                                                                    }`}
                                                                    placeholder="Nhập số seri"
                                                                    value={serial}
                                                                    onChange={(e) => handleOnChangeSeriCode(e, 'serial')}
                                                                />
                                                                {errorSerial && <span className="valid_payment">{errorSerial}</span>}
                                                            </div>
                                                        </div>
                                                        <div data-v-7697c618="" style={{ marginTop: 12 }}>
                                                            <div className="el-input el-input--suffix">
                                                                <input
                                                                    type="text"
                                                                    className={`el-input__inner payment ${errorCode ? 'valid_login' : ''}`}
                                                                    placeholder="Nhập mã thẻ"
                                                                    value={code}
                                                                    onChange={(e) => handleOnChangeSeriCode(e, 'code')}
                                                                />
                                                                {errorCode && <span className="valid_payment">{errorCode}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div data-v-706b0058="" className="empty-pmt">
                                Chọn phương thức thanh toán
                            </div>
                        )}
                    </div>

                    <div data-v-7697c618="" className="quick-login-form__btn authentication">
                        <button
                            data-v-0cf9d480=""
                            data-v-7697c618=""
                            className="el-button btn-primary--new-design el-button--primary"
                            disabled={
                                !packageSelect || !activeCode || !activeSerial || currentUser?.game?.code !== codeGame || loadingPayment
                            }
                            onClick={handleConfirmPayment}
                            style={{ maxWidth: '100%' }}
                        >
                            {loadingPayment ? (
                                <div className="quick-login-loading">
                                    <div className="quick-login-spin"></div>
                                </div>
                            ) : (
                                <span>Thanh toán ngay</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentConfirm;
