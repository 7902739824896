import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { convertCurrency } from '~/configs';
import { requestUserGetChargingws } from '~/services/payment';
import imageBackDropDefault from '~/assets/image/default-backdrop.webp';

function Result() {
    const [charging, setCharging] = useState(null);

    const { request_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCharging = async () => {
            const result = await requestUserGetChargingws(request_id);

            if (result.status === 200) {
                setCharging(result.data);

                document.title = `${result.data.app.title} | VNGGames Shop - Cổng nạp chính thức các game của VNG`;
            }
        };

        if (request_id) {
            document.getElementById('loadingContainer').classList.remove('hidden');

            fetchCharging().finally(() => {
                document.getElementById('loadingContainer').classList.add('hidden');
            });
        }
    }, [request_id]);

    useEffect(() => {
        let intervalId;

        if (charging?.status === 99) {
            const fetchCharging = async () => {
                const result = await requestUserGetChargingws(request_id);

                if (result.status === 200) {
                    setCharging(result.data);
                    document.title = `${result.data.app.title} | VNGGames Shop - Cổng nạp chính thức các game của VNG`;
                }
            };

            intervalId = setInterval(fetchCharging, 3000);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [charging, request_id]);

    const handlePaymentBack = () => {
        if (charging?.status === 99) {
            return;
        }

        navigate('/');
    };

    if (!charging) return;

    return (
        <div className="wrap-home-page wrap-home-page-redesign-ui result">
            <div data-v-f782b932="" className="wrap-backdrop-container">
                <div data-v-f782b932="" className="wrap-backdrop">
                    <picture data-v-35b7cd99="" data-v-f782b932="" className="backdrop-img" style={{ overflow: 'hidden' }}>
                        <source
                            data-v-35b7cd99=""
                            srcSet={charging.app.cover_url || imageBackDropDefault}
                            type="image/webp"
                            width="100%"
                            height="100%"
                            style={{ display: 'block' }}
                        />
                        <img
                            data-v-35b7cd99=""
                            src={charging.app.cover_url || imageBackDropDefault}
                            alt="backdropUrl"
                            width="100%"
                            height="100%"
                            style={{ display: 'block', objectFit: 'cover' }}
                        />
                    </picture>
                </div>
            </div>

            <div className="container pc">
                <div className="wrap-result">
                    <div className="result">
                        <div className="result-container">
                            <div className="result-header">
                                <div className="result-title">
                                    <div className="result-icon">
                                        {charging.status === 99 ? (
                                            <div className="result-loading">
                                                <div className="result-spin"></div>
                                            </div>
                                        ) : (
                                            <img
                                                src={
                                                    charging.status === 1
                                                        ? 'https://shop.vnggames.com/_next/image?url=/_next-public/images/result-success.png&w=3840&q=75'
                                                        : 'https://shop.vnggames.com/_next/image?url=/_next-public/images/result-failed.png&w=3840&q=75'
                                                }
                                                alt="Icon"
                                            />
                                        )}
                                    </div>
                                    <div
                                        className={`result-name ${
                                            charging.status === 99 ? 'pending' : charging.status === 1 ? 'success' : 'failed'
                                        }`}
                                    >
                                        {charging.status === 99
                                            ? 'ĐANG THANH TOÁN'
                                            : charging.status === 1
                                            ? 'THANH TOÁN THÀNH CÔNG'
                                            : 'THANH TOÁN THẤT BẠI'}
                                    </div>
                                </div>
                                <div className="result-info">
                                    <div className="wrap-info">
                                        <div className="info-item">
                                            <div className="name">Máy chủ:</div>
                                            <div className="value">Server {charging.user.server_id}</div>
                                        </div>
                                        <div className="info-item">
                                            <div className="name">ID nhân vật:</div>
                                            <div className="value">{charging.user.role_id}</div>
                                        </div>
                                        <div className="info-item">
                                            <div className="name">Nhân vật:</div>
                                            <div className="value">{charging.user.role_name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="result-body">
                                <div className="result-package">
                                    <div className="package-left">
                                        <div className="package-img">
                                            <img src={charging.package.image_url} alt={charging.package.title} />
                                        </div>
                                    </div>

                                    <div className="package-right">
                                        <div className="wrap-right">
                                            <div className="package-payment">
                                                <div className="payment-name">Tổng thanh toán</div>
                                                <div className="payment-value">{convertCurrency(charging.declared_value)} VND</div>
                                            </div>
                                            <div className="package-info">
                                                <div className="info-name">Tên gói nạp</div>
                                                <div className="info-value">{charging.package.title}</div>
                                            </div>
                                            <div className="package-info">
                                                <div className="info-name">Số lượng</div>
                                                <div className="info-value">x1</div>
                                            </div>
                                            <div className="package-info">
                                                <div className="info-name">Mã giao dịch</div>
                                                <div className="info-value">{charging.trans_id}</div>
                                            </div>
                                            <div className="package-info">
                                                <div className="info-name">Phương thức thanh toán</div>
                                                <div className="info-value">{charging.telco}</div>
                                            </div>
                                            <div className="package-info">
                                                <div className="info-name">Thời gian giao dịch</div>
                                                <div className="info-value">{moment(charging.created_at).format('HH:mm DD/MM/YYYY')}</div>
                                            </div>

                                            {charging.status !== 1 && charging.status !== 99 && (
                                                <div className="package-reason">
                                                    <div className="reason-title">Nguyên nhân</div>
                                                    <div className="reason-desc">Sai mật mã thẻ, giao dịch thất bại!</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="package-btn">
                                    <button disabled={charging.status === 99} onClick={handlePaymentBack}>
                                        Thanh toán lại
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Result;
