const benefits = [
    {
        id: 1,
        title: 'Ưu đãi hấp dẫn',
        icon_url: 'https://shop.vnggames.com/static/img/benefits/gift.png',
    },
    {
        id: 2,
        title: 'Vật phẩm độc quyền',
        icon_url: 'https://shop.vnggames.com/static/img/benefits/cup.png',
    },
    {
        id: 3,
        title: 'Thanh toán trực tiếp',
        icon_url: 'https://shop.vnggames.com/static/img/benefits/exchange-money.png',
    },
    {
        id: 4,
        title: 'Giá tốt nhất',
        icon_url: 'https://shop.vnggames.com/static/img/benefits/ticket-discount.png',
    },
];

function Benefits() {
    return (
        <div data-v-d96c7f20="" className="wrap-benefit">
            <div data-v-d96c7f20="" className="vng-benefit">
                <div data-v-d96c7f20="" className="benefit-header">
                    LỢI ÍCH KHI NẠP TẠI VNGGAMES
                </div>
                <div data-v-d96c7f20="" className="wrap-list-item">
                    {benefits.map((benefit) => (
                        <div data-v-d96c7f20="" className="item-benefit" key={benefit.id}>
                            <img data-v-d96c7f20="" alt="icon" className="icon-benefit" src={benefit.icon_url} />
                            <div data-v-d96c7f20="" className="title">
                                {benefit.title}
                            </div>
                        </div>
                    ))}
                </div>

                <div data-v-d96c7f20="" className="wrap-mb">
                    <div data-v-d96c7f20="" className="group-benefit">
                        <div data-v-d96c7f20="" className="item-benefit">
                            <img
                                data-v-d96c7f20=""
                                alt="icon"
                                className="icon-benefit"
                                src="https://shop.vnggames.com/static/img/benefits/gift.png"
                            />
                            <div data-v-d96c7f20="" className="title">
                                Ưu đãi hấp dẫn
                            </div>
                        </div>
                        <div data-v-d96c7f20="" className="item-benefit">
                            <img
                                data-v-d96c7f20=""
                                alt="icon"
                                className="icon-benefit"
                                src="https://shop.vnggames.com/static/img/benefits/exchange-money.png"
                            />
                            <div data-v-d96c7f20="" className="title">
                                Thanh toán trực tiếp
                            </div>
                        </div>
                    </div>
                    <div data-v-d96c7f20="" className="group-benefit custom">
                        <div data-v-d96c7f20="" className="item-benefit">
                            <img
                                data-v-d96c7f20=""
                                alt="icon"
                                className="icon-benefit"
                                src="https://shop.vnggames.com/static/img/benefits/cup.png"
                            />
                            <div data-v-d96c7f20="" className="title">
                                Vật phẩm độc quyền
                            </div>
                        </div>
                        <div data-v-d96c7f20="" className="item-benefit">
                            <img
                                data-v-d96c7f20=""
                                alt="icon"
                                className="icon-benefit"
                                src="https://shop.vnggames.com/static/img/benefits/ticket-discount.png"
                            />
                            <div data-v-d96c7f20="" className="title">
                                Giá tốt nhất
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Benefits;
