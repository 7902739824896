function PaymentMethod({ telcos = [], telcoSelect, setTelcoSelect }) {
    return (
        <div data-v-4c23a4d5="" className="content-box content-box-redesign-ui">
            <div className="mask"></div>
            <div className="content-box__header">
                <div className="content-box__header-container">
                    <span className="content-box__header-number">3</span>
                    <span className="content-box__header-content">Chọn phương thức thanh toán</span>
                </div>
            </div>
            <div className="content-box__content">
                {/* <div data-v-6d021b4f="" data-v-4c23a4d5="" style={{ display: 'none' }}>
                    <div className="el-skeleton is-animated">
                        <div data-v-6d021b4f="" className="wrap-pmt-skeleton">
                            {Array(4)
                                .fill()
                                .map((_, index) => (
                                    <div key={index} data-v-6d021b4f="" className="pmt-item">
                                        <div data-v-6d021b4f="" className="avatar el-skeleton__item el-skeleton__image">
                                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M64 896V128h896v768H64z m64-128l192-192 116.352 116.352L640 448l256 307.2V192H128v576z m224-480a96 96 0 1 1-0.064 192.064A96 96 0 0 1 352 288z"></path>
                                            </svg>
                                        </div>
                                        <div data-v-6d021b4f="" className="wrap-title">
                                            <div data-v-6d021b4f="" className="title el-skeleton__item el-skeleton__h3"></div>
                                            <div data-v-6d021b4f="" className="title el-skeleton__item el-skeleton__h3"></div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div> */}

                <div
                    data-v-4c23a4d5=""
                    className="row-custom equal-height-row el-row method-list"
                    style={{ marginLeft: '-8px', marginRight: '-8px' }}
                >
                    {telcos.map((telco, index) => (
                        <div
                            key={index}
                            data-v-4c23a4d5=""
                            className="equal-height-column el-col el-col-6 method-item"
                            style={{ paddingLeft: '8px', paddingRight: '8px' }}
                        >
                            <div
                                data-v-5c4a3c97=""
                                data-v-4c23a4d5=""
                                className={`wrap-group-payment-method wrap-group-payment-method--hover ${
                                    telcoSelect?.id === telco.id ? 'selected' : ''
                                }`}
                                onClick={() => setTelcoSelect(telco)}
                            >
                                <div data-v-5c4a3c97="" className="top-content is-vn-market">
                                    <div data-v-5c4a3c97="" className="--icon">
                                        <div data-v-5c4a3c97="" className="--icon__img">
                                            <img className="payment-method__img" alt={telco.title} src={telco.image_url} />
                                        </div>
                                    </div>
                                    <div data-v-5c4a3c97="" className="--name">
                                        <div data-v-5c4a3c97="" className="text-name">
                                            {telco.title}
                                        </div>
                                    </div>
                                </div>
                                <div data-v-5c4a3c97="" className={`bottom-content ${telcoSelect?.id === telco.id ? 'selected' : ''}`}>
                                    {telcoSelect?.id === telco.id && <i data-v-5c4a3c97="" className="el-icon-check"></i>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PaymentMethod;
