function ForYou() {
    return (
        <div data-v-35942c86="" className="section-recommend" style={{ display: 'none' }}>
            <div data-v-35942c86="" className="recommend-header">
                DÀNH CHO BẠN
            </div>
            <div data-v-35942c86="" className="content-section-recommend">
                <div data-v-35942c86="">
                    <div data-v-35942c86="" title="Play Together VNG" className="wrap-game">
                        <div className="top-item">
                            <div className="wrap-img-item">
                                <img alt="tag-game" className="tag-game" src="https://shop.vnggames.com/static/img/tag-clickvi.svg" />
                                <picture>
                                    <source
                                        type="image/webp"
                                        srcSet="https://scdn-img.vng.games/mainsite/images/ptgvn-icon.png?size=origin&iswebp=1"
                                    />
                                    <img
                                        src="https://scdn-stc.vng.games/mainsite/images/ptgvn-icon.png"
                                        alt="thumb-img-game"
                                        className="img-game"
                                    />
                                </picture>
                            </div>
                            <div className="titleGame">Play Together VNG</div>
                        </div>
                        <div className="wrap-btnNap">
                            <button className="btnNap">Nạp ngay</button>
                        </div>
                    </div>
                </div>
                <div data-v-35942c86="" className="swiper swiper-initialized swiper-horizontal">
                    <div data-v-35942c86="" className="swiper-wrapper" style={{ transitionDuration: '0ms' }}>
                        <div data-v-35942c86="" className="swiper-slide">
                            <div data-v-35942c86="" title="Play Together VNG" className="wrap-game">
                                <div className="top-item">
                                    <div className="wrap-img-item">
                                        <img
                                            alt="tag-game"
                                            className="tag-game"
                                            src="https://shop.vnggames.com/static/img/tag-clickvi.svg"
                                        />
                                        <picture>
                                            <source
                                                type="image/webp"
                                                srcSet="https://scdn-img.vng.games/mainsite/images/ptgvn-icon.png?size=origin&iswebp=1"
                                            />
                                            <img
                                                src="https://scdn-stc.vng.games/mainsite/images/ptgvn-icon.png"
                                                alt="thumb-img-game"
                                                className="img-game"
                                            />
                                        </picture>
                                    </div>
                                    <div className="titleGame">Play Together VNG</div>
                                </div>
                                <div className="wrap-btnNap">
                                    <button className="btnNap">Nạp ngay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForYou;
