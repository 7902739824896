import { convertCurrency } from '~/configs';
import { useEffect, useState } from 'react';

function SelectPackage({ packages = [], packageSelect, setPackageSelect }) {
    const [tags, setTags] = useState([]);
    const [products, setProducts] = useState([]);
    const [activeTagIndex, setActiveTagIndex] = useState(null);

    useEffect(() => {
        for (const pack of packages) {
            if (pack.tag) {
                setTags((prevTags) => prevTags.concat(pack.tag));
            }
        }

        setProducts(packages);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packages]);

    const handleSelectTag = (tag, index) => {
        setActiveTagIndex(index);

        const filteredProducts = products.filter((product) => product.tag === tag);
        setProducts(filteredProducts);
    };

    return (
        <div data-v-196c0b64="" className="selectPackage content-box content-box-redesign-ui package-list-redesign">
            <div className="mask"></div>
            <div className="content-box__header">
                <div className="content-box__header-container">
                    <span className="content-box__header-number">2</span>
                    <span className="content-box__header-content">Chọn gói</span>
                </div>
            </div>
            <div className="content-box__content">
                <div data-v-196c0b64="" className="packageGroup package-group-redesign">
                    {tags.map((tag, index) => (
                        <div key={index} data-v-1d705422="" className="button-wrapper">
                            <button
                                data-v-1d705422=""
                                type="Button"
                                className={`el-button button el-button--default default ${activeTagIndex === index ? 'selected' : ''}`}
                                onClick={() => handleSelectTag(tag, index)}
                            >
                                <span>{tag}</span>
                            </button>
                        </div>
                    ))}
                </div>

                <div
                    data-v-196c0b64=""
                    className="listProduct el-row productWrapperRedesign package-list"
                    style={{ marginLeft: '-8px', marginRight: '-8px' }}
                >
                    {products.map((product, index) => (
                        <div
                            key={index}
                            data-v-196c0b64=""
                            className="col-custom el-col el-col-8 package-item-wrapper"
                            style={{ paddingLeft: '8px', paddingRight: '8px' }}
                        >
                            <div data-v-5a53f877="" className="product-wrapper">
                                <div
                                    data-v-5a53f877=""
                                    className={`package-item-redesign ${packageSelect?.id === product.id ? 'selected' : ''}`}
                                    onClick={() => setPackageSelect(product)}
                                >
                                    <picture data-v-35b7cd99="" className="handle-image" style={{ overflow: 'hidden' }}>
                                        <img
                                            data-v-35b7cd99=""
                                            src={product.image_url}
                                            alt={product.title}
                                            width="100%"
                                            height="100%"
                                            style={{ display: 'block', objectFit: 'cover' }}
                                        />
                                    </picture>
                                    <div data-v-5a53f877="" className="wrap-content-package">
                                        <div data-v-5a53f877="" className="title">
                                            <div data-v-5a53f877="" className="name">
                                                {product.title}
                                            </div>
                                        </div>
                                        <div data-v-5a53f877="" className="bottom-package">
                                            <div data-v-5a53f877="" className="price-wrapper">
                                                <div data-v-5a53f877="" className="price">
                                                    {convertCurrency(product.amount.value)} VND
                                                </div>
                                            </div>
                                            <div
                                                data-v-5a53f877=""
                                                className={`btn-plus ${packageSelect?.id === product.id ? 'btn-plus-selected' : ''}`}
                                            >
                                                {packageSelect?.id === product.id ? (
                                                    <i data-v-5a53f877="" className="el-icon-check"></i>
                                                ) : (
                                                    <i data-v-5a53f877="" className="el-icon-plus"></i>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SelectPackage;
