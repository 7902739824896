import Banner from '../Banner';
import { Fragment } from 'react';
import Landing from '../Landing';
import Benefits from '../Benefits';

function Billing() {
    return (
        <Fragment>
            <Banner />

            <Landing navigateGame="/billing/" />

            <Benefits />
        </Fragment>
    );
}

export default Billing;
