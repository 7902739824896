import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div data-v-55c0508b="" className="wrap-footer">
            <div data-v-55c0508b="" className="footer">
                <div data-v-55c0508b="" className="top">
                    <Link data-v-55c0508b="" to="/" className="router-link-exact-active active">
                        <img
                            data-v-55c0508b=""
                            alt="VNG-Games-logo"
                            className="logo"
                            src="https://shop.vnggames.com/static/img/logoshop2.png"
                        />
                    </Link>
                    <div data-v-55c0508b="" className="footerPayzing">
                        <div data-v-55c0508b="" className="colPayzing">
                            <div data-v-55c0508b="" className="title">
                                Khám phá
                            </div>
                            <div data-v-55c0508b="" className="item-footer">
                                <Link data-v-55c0508b="" to="/">
                                    Đại lý thẻ Zing
                                </Link>
                            </div>
                        </div>
                        <div data-v-55c0508b="" className="colPayzing">
                            <div data-v-55c0508b="" className="title">
                                Hỗ Trợ
                            </div>
                            <Link data-v-55c0508b="" to="/">
                                Hướng dẫn nạp tiền
                            </Link>
                            <Link data-v-55c0508b="" to="/">
                                Câu hỏi thường gặp
                            </Link>
                            <div data-v-55c0508b="" className="item-footer">
                                <Link data-v-55c0508b="" to="/">
                                    Chăm sóc khách hàng
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-v-55c0508b="" className="term term-vn">
                    <div data-v-55c0508b="">©Copyright Thegioicode.vn</div>
                    <div data-v-55c0508b="" className="dflex">
                        <div data-v-55c0508b="" className="item-footer">
                            <Link data-v-55c0508b="" to="/">
                                Điều khoản dịch vụ
                            </Link>
                        </div>
                        <div data-v-55c0508b="" className="item-footer">
                            <Link data-v-55c0508b="" to="/">
                                Chính sách bảo mật
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
